body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home-section {
  padding-top: 20px;
  padding-bottom: 40px;
}

.home-banner {
  background-color: #D3FBFF !important;

  background: linear-gradient(to bottom, rgba(30, 70, 112, 0.52), rgba(30, 70, 112, 0.52)), url(images/banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: whitesmoke;
  /*-webkit-text-stroke-width: 1px;*/
  /*-webkit-text-stroke-color: black;*/
}
.home-banner-row {
  padding-top: 50px;
  padding-bottom: 50px;
}
.home-about {
  background-color: #ffffff;
}

.pricing-banner {
  background-color: #1e477a !important;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: whitesmoke;
  /*-webkit-text-stroke-width: 1px;*/
  /*-webkit-text-stroke-color: black;*/
}

.jumbotron {
  border-radius: 0 !important;
}

.navbar-brand {
  font-weight: 500;
  color: #1E477A!important;
}

.navbar-light .navbar-nav .nav-link {
  color: #1E477A;
}

.logo-list-item{
  margin: 0;
  padding: 25px 0 25px 55px;
  list-style: none;
  background-image: url("images/ThanksChatLogo.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 40px;
}
/*.logo-list-item:before{*/
/*  content: '';*/
/*  display: inline-block;*/
/*  height: 15px;*/
/*  width: 15px;*/
/*  background-image: url('images/ThanksChatLogo.png');*/
/*}*/